<template>
    <div>
        <el-row class="container">
            <el-row class="sliderbar-nav">
                <el-col :span="18">
                    <div v-for="item in BreadMenu" :key="item.id" @click="switchMenu(item)"
                         :class="SelectedBread.id === item.id?'breadcrumb-nav-item active':'breadcrumb-nav-item'">{{item.name}}
                    </div>
                </el-col>
                <el-col :span="6">
                    <el-button size="small" type="primary" icon="el-icon-plus" @click="addTemplate" style="float: right;">{{$t('m.pubilc.tinajia')}}</el-button>
                </el-col>
            </el-row>
            <el-row type="flex" style="background: #f8f8f8;align-items: center;padding: 0 18px;">
                <el-col :span="20" style="padding-top: 18px;" >
                    <el-form :inline="true"  size="mini" class="searchForm">
                        <el-form-item :label="$t('m.template.nameOrnum')">
                            <el-input v-model="Filter.filterName" size="mini" :placeholder="$t('m.goods.pleaseInput')"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('m.template.templateGroup')">
                            <el-select v-model="Filter.industryId"
                                   clearable
                                   :placeholder="$t('m.goods.qingxuanze')">
                                <el-option
                                        v-for="item in Groups"
                                        :key="item.id"
                                        :label="item.industryName"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="$t('m.goods.zongxiaoliang')">
                            <el-input v-model="Filter.minSales" class="short-input"></el-input>
                            <span class="margin-lr-10">-</span>
                            <el-input v-model="Filter.maxSales" class="short-input"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('m.pubilc.xuhao')">
                            <el-input v-model="Filter.startSerial" class="short-input"></el-input>
                            <span class="margin-lr-10">-</span>
                            <el-input v-model="Filter.endSerial" class="short-input"></el-input>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="4">
                    <el-row type="flex" justify="end">
                        <el-button type="primary" size="small" @click="getTempList">{{$t("m.pubilc.CheckFor")}}</el-button>
                        <el-button size="small" @click="unsetFilter">{{$t("m.pubilc.chongzhi")}}</el-button>
                    </el-row>
                </el-col>
            </el-row>

            <el-row class="eltable cz-table">
                <el-table
                        ref="multipleTable"
                        :data="Result.list"
                        tooltip-effect="dark"
                        @selection-change="handleSelectionChange"
                        >
<!--                    @cell-mouse-enter="hoverEnter"-->
<!--                    @cell-mouse-leave="hoverLeave"-->
<!--                    -->
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                            label="模板/价格">
                        <template slot-scope="scope">
                            <el-row type="flex">
                                <el-row class="goods-image-box"
                                        :style="'background-image: url('+ imageUrl(scope.row.coverUrl) + ')'"></el-row>
                                <div class="goods-name-price">
                                    <el-row class="goods-name">{{scope.row.name}}
                                        <el-popover
                                                placement="top"
                                                v-model="scope.row._showRenameModal"
                                                width="250">
                                            <p>{{$t("m.goods.gaiming")}}</p>
                                            <el-input style="margin: 5px 0"
                                                      :placeholder="$t('m.goods.buchaoguoduoshaogezifu')"
                                                      size="mini"
                                                      :minlength="1"
                                                      v-model="scope.row._reName"
                                                      :maxlength="255"/>

                                            <div style="text-align: right; margin: 0">
                                                <el-button size="mini"
                                                           @click="scope.row._showRenameModal = false">{{$t("m.pubilc.cancel")}}
                                                </el-button>
                                                <el-button type="primary"
                                                           size="mini"
                                                           @click="nameCommitEdit(scope.row)">{{$t("m.pubilc.confirm")}}
                                                </el-button>
                                            </div>
                                            <i v-show="HoverGoodsId === scope.row.id"
                                               slot="reference"
                                               @click="nameWillEdit(scope.row)"
                                               class="el-icon-edit-outline"/>
                                        </el-popover>
                                    </el-row>
                                    <div class="goods-price">¥{{scope.row.basePrice}}
                                        <el-popover
                                                placement="top"
                                                v-model="scope.row._showRepriceModal"
                                                width="250">
                                            <p>{{$t("m.goods.gaijia")}}</p>
                                            <el-input style="margin: 5px 0"
                                                      placeholder="0"
                                                      size="mini"
                                                      :minlength="1"
                                                      v-model="scope.row._rePrice"
                                                      :maxlength="10"/>

                                            <div style="text-align: right; margin: 0">
                                                <el-button size="mini"
                                                           @click="scope.row._showRepriceModal = false">{{$t("m.pubilc.cancel")}}
                                                </el-button>
                                                <el-button type="primary"
                                                           size="mini"
                                                           @click="priceCommitEdit(scope.row)">{{$t("m.pubilc.confirm")}}
                                                </el-button>
                                            </div>
                                            <i v-show="HoverGoodsId === scope.row.id"
                                               slot="reference"
                                               @click="priceWillEdit(scope.row)"
                                               class="el-icon-edit-outline"/>
                                        </el-popover>

                                    </div>
                                </div>
                            </el-row>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="visitsNumbers"
                            :label="$t('m.goods.fangwenliang')"
                            align="center"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            prop="browseNumbers"
                            :label="$t('m.goods.liulanliang')"
                            align="center"
                            width="100">
                    </el-table-column>
<!--                    <el-table-column-->
<!--                            prop="stock"-->
<!--                            :label="$t('m.goods.kucun')"-->
<!--                            align="center"-->
<!--                            width="100">-->
<!--                    </el-table-column>-->
                    <el-table-column
                            prop="salesCount"
                            :label="$t('m.goods.zongxiaoliang')"
                            align="center"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            :label="$t('m.pubilc.xuhao')"
                            align="center"
                            width="150">
                        <template slot-scope="scope">
                            <el-input-number size="mini"
                                             v-model="scope.row.sort"
                                             :min="0"
                                             @change="updateOrder(scope.row)"/>
                        </template>
                    </el-table-column>
                    <el-table-column
                            width="200"
                            align="center"
                            :label="$t('m.pubilc.caozuo')">
                        <template slot-scope="scope">
<!--                            class="action-button"-->
                            <el-button type="text"
                                       size="mini"
                                       @click="handleEdit(scope.$index, scope.row)">{{$t('m.pubilc.bianji')}}</el-button>
<!--                            <el-button type="text"-->
<!--                                       size="mini"-->
<!--                                       style="margin-left: 0;"-->
<!--                                       @click="handleCopy(scope.$index, scope.row)">{{$t('m.pubilc.fuzhi')}}</el-button>-->
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>

            <el-row style="margin-top: 20px" type="flex" justify="space-between">
                <div>
                    <span class="select-text">{{$t("m.goods.yixuanshangpin")}} {{multipleSelection.length}} </span>

                    <el-popover
                            placement="top"
                            width="250"
                            v-model="ShowBatchGroupModal">
                        <p>{{$t("m.goods.quedingxiugaimorenfenzu")}}</p>
                        <el-select v-model="BatchGroupId"
                                   :placeholder="$t('m.goods.qingxuanze')"
                                   style="margin: 10px 0"
                                   size="mini">
                            <el-option
                                    v-for="item in Groups"
                                    :key="item.id"
                                    :label="item.industryName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                        <div style="text-align: right; margin: 0">
                            <el-button size="mini" @click="ShowBatchGroupModal = false">
                                {{$t("m.pubilc.cancel")}}
                            </el-button>
                            <el-button type="primary" size="mini"
                                       @click="modifyGoodsGroup()">
                                {{$t("m.pubilc.confirm")}}
                            </el-button>
                        </div>
                        <el-button size="small"
                                   slot="reference">
                            {{$t("m.goods.gaifenzu")}}
                        </el-button>
                    </el-popover>

                    <el-popover
                            placement="top"
                            width="250"
                            v-model="ShowBatchDeleteModal">
                        <p>{{$t("m.goods.qudingshanchushangpin")}}</p>
                        <div style="text-align: right; margin: 0">
                            <el-button size="mini" @click="ShowBatchDeleteModal = false">
                                {{$t("m.pubilc.cancel")}}
                            </el-button>
                            <el-button size="mini"
                                       @click="deleteGoods()">
                                {{$t("m.pubilc.confirm")}}
                            </el-button>
                        </div>
                        <el-button size="small"
                                   slot="reference"
                                   style="margin:0 10px">
                            {{$t("m.pubilc.shanchu")}}
                        </el-button>
                    </el-popover>

                    <el-popover
                            placement="top"
                            width="250"
                            v-model="ShowBatchStatusModal">
                        <p>{{$t("m.pubilc.confirm")}}{{SelectedBread.id === 0 ? $t('m.goods.xiajia') : $t('m.goods.shangjia')}}{{$t("m.goods.commodity")}}?</p>
                        <div style="text-align: right; margin: 0">
                            <el-button size="mini" @click="ShowBatchStatusModal = false">
                                {{$t("m.pubilc.cancel")}}
                            </el-button>
                            <el-button type="warning" size="mini"
                                       @click="togglesGoodStatus()">
                                {{$t("m.pubilc.confirm")}}
                            </el-button>
                        </div>
                        <el-button size="small" v-if="SelectedBread.id!=1"
                                   slot="reference">
                            {{SelectedBread.id === 0 ? $t('m.goods.xiajia') : $t('m.goods.shangjia')}}
                        </el-button>
                    </el-popover>
                </div>
                <el-row type="flex">
                    <el-pagination
                            background
                            :pageSize="pageSize"
                            :current-page.sync="pageNum"
                            @current-change="pageNumChanged"
                            layout="total,prev, pager, next"
                            :total="Result.total">
                    </el-pagination>
                </el-row>
            </el-row>
        </el-row>
    </div>
</template>

<script>
    import {getTempList,getIndustries,updateSerialNumber,updateIndustry,offShelfTemplate,removeTemplate} from 'request/api/template';
    import {getImageUrl} from "../../../utils/ImageUtils";


    export default {
        name: 'material',
        data() {
            return {
                BreadMenu: [{
                    id:0,
                    name:'销售中'
                },{
                    id:1,
                    name:'已下架'
                }],
                SelectedBread: {},
                Filter:{
                    filterName:'',
                    filterGroup:'',
                    minSales:'',
                    maxSales:'',
                    startSerial: '',
                    endSerial: '',
                    industryId:null,
                },
                Result: {},//商品列表
                Groups:[],//筛选框中的商品分组
                HoverGoodsId:'',//鼠标滑过展示编辑按钮的id
                ShowBatchDeleteModal:false,
                ShowBatchStatusModal:false,
                ShowBatchGroupModal:false,
                BatchGroupId:'',
                pageSize: 10,
                pageNum: 1,

                multipleSelection: [],

            };
        },

        mounted() {
            this.SelectedBread = this.BreadMenu[0];
            this.getTempList();
            this.getIndustries();
        },
        methods:{
            //图片判断
            imageUrl(url){
                return getImageUrl(url);
            },
            //获取模板数据
            getTempList(){
                let _this = this;
                let params = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    status:this.SelectedBread.id,
                }
                if(this.Filter.minSales.length>0){
                    params.startSalesVolume = this.Filter.minSales
                }
                if(this.Filter.maxSales.length>0){
                    params.endSalesVolume = this.Filter.maxSales
                }
                if(this.Filter.filterName.length>0){
                    params.name = this.Filter.filterName
                }
                if(this.Filter.startSerial.length>0){
                    params.startOrderNum = this.Filter.startSerial
                }
                if(this.Filter.endSerial.length>0){
                    params.endOrderNum = this.Filter.endSerial
                }
                if(this.Filter.industryId!=null){
                    params.industryId = this.Filter.industryId
                }
                getTempList(params).then(res=>{
                    // console.log(res)
                    if(res.code==200){
                        let result = res.data;
                        result.list.forEach(goods=>{
                            goods._reName = goods.name; //给改名做准备
                            goods._showRenameModal = false;

                            goods._rePrice = goods.basePrice;//给改价做准备
                            goods._showRepriceModal = false;

                            goods.images = goods.thumbUrl.split(",");
                        });
                        _this.Result = result
                    }
                })
            },
            //获取分组
            getIndustries(){
                getIndustries().then(res=>{
                    if(res.code==200){
                        this.Groups = res.data
                    }
                })
            },
            switchMenu(item){
                this.SelectedBread = item;
                this.getTempList()
            },
            pageNumChanged(page){
                this.pageNum = page;
                this.getTempList()
            },
            //清空筛选条件
            unsetFilter(){
                this.Filter = {
                    filterName:'',
                    filterGroup:'',
                    minSales:'',
                    maxSales:'',
                    startSerial: '',
                    endSerial: '',
                    industryId:null,
                };
                this.getTempList()
            },
            //修改序号
            updateOrder(row){
                updateSerialNumber({
                    serialNumber:row.sort,
                    templateId:row.id
                }).then(res=>{
                    if(res.code==200){
                        this.$notify.success({
                            title: this.$t('m.pubilc.tips'),
                            message: this.$t('m.pubilc.tip_success'),
                        });
                        this.getTempList()
                    }
                })
            },
        //    删除操作
            deleteGoods(){
                let params = {
                    templateIds:this.multipleSelection.map(item=>item.id).join(","),
                }
                removeTemplate(params).then(res=>{
                    if(res.code==200){
                        this.ShowBatchDeleteModal = false;
                        this.$notify.success({
                            title: this.$t('m.pubilc.tips'),
                            message: this.$t('m.pubilc.tip_success'),
                        });
                        this.getTempList()
                    }
                })
            },
        //    下架
            togglesGoodStatus(){
                let params = {
                    templateIds:this.multipleSelection.map(item=>item.id).join(","),
                }
                offShelfTemplate(params).then(res=>{
                    if(res.code==200){
                        this.ShowBatchStatusModal = false;
                        this.$notify.success({
                            title: this.$t('m.pubilc.tips'),
                            message: this.$t('m.pubilc.tip_success'),
                        });
                        this.getTempList()
                    }
                })
            },
        //    表格多选
            handleSelectionChange(list){
                this.multipleSelection = list
            },
        //    修改分组
            modifyGoodsGroup(){
                if(this.multipleSelection.length==0){
                    this.$notify.error({
                        title: this.$t('m.pubilc.tips'),
                        message: '请选择商品',
                    });
                    return;
                }
                if (!this.BatchGroupId){
                    this.$notify.error({
                        title: this.$t('m.pubilc.tips'),
                        message: '请选择分组',
                    });
                    return;
                };
                let data = {
                    templateIds:this.multipleSelection.map(item=>item.id).join(","),
                    industryId:this.BatchGroupId,
                };
                updateIndustry(data).then(res=>{
                    if(res.code==200){
                        this.ShowBatchGroupModal = false;
                        this.$notify.success({
                            title: this.$t('m.pubilc.tips'),
                            message: this.$t('m.pubilc.tip_success'),
                        });
                        this.getTempList()
                    }
                })
            },
        //    新增模板
            addTemplate() {
                this.$router.push({path: '/manager/template/allTemplate/editor_template'});
            },
        //    编辑
            handleEdit(index,row){
                this.$router.push({
                    path: '/manager/template/allTemplate/editor_template',
                    query: {id: row.id}
                });
            },
        //   复制
            handleCopy(index,row){

            }
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    @import "../../../assets/style/table";
    .short-input {
        max-width: 80px !important;
    }

    .container {
        padding: 15px;
        min-width: 750px;
        min-height: 500px;
        box-shadow: none;
        background-color: white;
    }

    .sliderbar-nav {
        border-bottom: 1px solid #ebedf0;
        margin-bottom: 10px;
        margin-top: -13px;
        height: 50px;
        display: flex;
        align-items: center;
    }

    .breadcrumb-nav-item {
        vertical-align: middle;
        text-decoration: none;
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        min-width: 0;
        border: none;
        background: transparent;
        font-size: 14px;
        color: #666;
        text-align: center;
        box-sizing: border-box;
        cursor: pointer;
    }

    .breadcrumb-nav-item.active:after {
        display: block;
        color: #333;
        border-bottom: 2px solid @color-primary;
        content: "";
        margin: 0;
    }

    .breadcrumb-nav-item.active {
        color: #333;
    }

    .app-filter-region {
        margin-bottom: 15px;
    }

    .margin-lr-10 {
        margin: 0 10px;
    }

    .eltable {
        margin-top: 20px;
        overflow: visible;
    }

    .el-table th {
        background-color: #f8f8f8;
    }

    .eltable>.cell {
        overflow: visible;
    }

    .select-text {
        line-height: 40px;
        margin-right: 10px;
    }

    .goods-image-box {
        width: 45px;
        height: 60px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .goods-name-price {
        margin-left: 10px;
    }

    .goods-name {
        color: #155bd4;
    }

    .goods-price {
        color: #ff6600;
    }

    .el-icon-edit-outline {
        color: #999;
        position: relative;
    }

    .category-add-input {
        position: absolute;
        background: #fff;
        border-radius: 2px;
        padding: 10px 20px;
        z-index: 2;
        color: #333;
        top: 20px;
        left: 0;
        box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
        width: 200px;
    }

    .category-add-input-icon {
        top: 20px;
        content: "";
        display: block;
        position: absolute;
        z-index: 0;
        width: 6px;
        height: 6px;
        background: #fff;
        box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
        overflow: hidden;
        transform: translate(-50%, -50%) rotate(45deg);
        left: 100%;
    }

    .category-add-input-content {
        background-color: #fff;
        text-align: left;
    }
    .searchForm{
        display: flex;
        flex-wrap: wrap;
        /deep/ .el-form-item{
            flex-shrink: 0;
            width: 310px;
            display: flex;
            flex-wrap: nowrap;
        }
        /deep/ .el-form-item__label{
            white-space: nowrap;
            width: 120px!important;
            text-align: right;
            display: block;
            flex-shrink: 0;
        }
        /deep/ .el-form-item__content{
            width: 100%;
        }
        /deep/ .el-input{
            width: 100%;
        }
    }
</style>
